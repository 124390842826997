<template>
  <div
    class="two_container"
    :style="{
      background: `${data.background}`,
    }"
  >
    <template v-for="item in data.floor">
      <div :key="item.id" v-if="item.type == 0" class="banner">
        <Banner :list="item.config.banner" />
      </div>
      <swipe-card-list
        :key="item.id"
        v-if="item.type == 1"
        :config="item.config"
        :title="item.title"
        :background="item.background"
      />
      <goods-card-list
        :key="item.id"
        v-if="item.type == 2"
        :config="item.config"
        :title="item.title"
        :background="item.background"
      />
    </template>
  </div>
</template>

<script>
import Banner from "../components/banner";
import goodsCardList from "../components/goodsCardList";
import swipeCardList from "../components/swipeCardList";
export default {
  props: {
    data: Object,
  },
  components: {
    Banner,
    goodsCardList,
    swipeCardList,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.two_container {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}
</style>
